<template>
	<el-form-item prop="payMethd" class="border">
		<span>付款方式<span class="start">*</span></span>
    <el-tooltip class="item" effect="light" content="售后订单扣减后剩余的金额，可用于贷款平台退费和学员退费" placement="top-start">
      <div slot="content">
		<!-- 1、学员余额，售后订单退费至学员学习账号，下次报名时可使用余额付款<br/> -->
      1、银行账户，售后订单退费至学员银行账户，下次报名时需学员重新付款</div>
      <i class="el-icon-question" style="color: #9BACBF;font-size: 18px;margin-left: 8px"></i>
    </el-tooltip>
		<CheckBox
			style="margin-left: -10px"
			:list="[{label: '银行账户', value: refundPayTypeEnum.bank}]"
			v-bind:select.sync="dropoutForm.payType"
			ref="check"
      @update:select="selectChange"
		/>
		<div v-show="dropoutForm.payType == '0'" style="margin-top: 10px" class="radio1-content">
			<el-form-item style="display: inline-block;margin-right: 24px;width:282px;" prop="a">
			<!-- <v-autocomplete
					v-model.trim="dropoutForm.dropOrderNo"
					:items="canDropoutList"
					:menu-props="{ bottom: true, offsetY: true }"
					item-text="mobile"
					item-value="orderNo"
					placeholder="请选择学员账号"
					outlined
					:dense="true"
					:height="42"
					hide-details="auto"
					@change="changeDropoutMobile"
					:readonly="disabledChangeDropOrderNo"
				></v-autocomplete> -->
        <v-text-field
					hide-details="auto"
					v-model.trim="dropoutForm.dropMobile"
					label="学员账号"
					disabled
					outlined
					:dense="true"
					:height="42"
				></v-text-field>
			</el-form-item>
			<el-form-item style="display: inline-block;width:282px;">
				<v-text-field
					hide-details="auto"
					v-model.trim="dropoutForm.dropName"
					label="学员姓名"
					disabled
					outlined
					:dense="true"
					:height="42"
				></v-text-field>
			</el-form-item>
		</div>
		<div v-show="dropoutForm.payType == '1' || refundPayTypeEnum.bank" style="margin-top: 10px" class="radio2-content">
			<el-form-item style="margin-right: 24px;display: inline-block;width:282px;margin-bottom: 12px" prop="bankAccountName">
				<v-text-field
					hide-details="auto"
					v-model.trim="dropoutForm.bankAccountName"
					placeholder="学员开户名"
					outlined
					:dense="true"
					:height="42"
				></v-text-field>
			</el-form-item>
			<el-form-item style="margin-right: 24px;display: inline-block;width:282px;" prop="bank">
				<v-text-field
					hide-details="auto"
					v-model.trim="dropoutForm.bank"
					placeholder="学员开户行及网点"
					outlined
					:dense="true"
					:height="42"
				></v-text-field>
			</el-form-item>
			<el-form-item style="display: inline-block;width:282px;" prop="bankAccount">
				<v-text-field
					hide-details="auto"
					v-model.trim="dropoutForm.bankAccount"
					placeholder="学员户行账号"
					outlined
					:dense="true"
					:height="42"
				></v-text-field>
			</el-form-item>
			<div style="margin-top: 10px;">
				<el-form-item style="display: inline-block;width:588px;" prop="ncBankAccountPk">
					<v-autocomplete
						v-model.trim="dropoutForm.ncBankAccountPk"
						:items="payBankList"
						:menu-props="{ bottom: true, offsetY: true }"
						item-text="accountName"
						item-value="bankAccountPk"
						placeholder="付款银行"
						outlined
						:dense="true"
						:height="42"
						:clearable="true"
						clear-icon="$clear"
						hide-details="auto"
						@change="onBankAccountPkChange"
					>
					</v-autocomplete>
				</el-form-item>
			</div>
		</div>
	</el-form-item>
</template>

<script>
import { refundPayTypeEnum } from '@/views/order/constants'
export default {
	props: {
		orderTable: Array,
		dropoutForm: Object,
		dropInfo: Object,
		disabledChangeDropOrderNo: {
			type: Boolean,
			defalut: false
		}
	},
	components: {
		CheckBox: () => import("components/check-box"),
	},
	data(){
		return {
			refundPayTypeEnum,
			payBankList: [],
		}
	},
	computed: {
		canDropoutList(){
      let canDropoutList = []
      let orderTable = this.orderTable;
      for(let i in orderTable){
        canDropoutList.push(orderTable[i])
        if( !orderTable[i].mobile ){
          this.$message.error("学员账号不存在")
        }
      }
      console.log('[canDropoutList]', canDropoutList);
      return canDropoutList
    },
	},
	watch: {
		orderTable(){
			this.getPayBankList()
		}
	},
	mounted(){
		this.getPayBankList()
	},
	methods: {
		changeDropoutMobile(val){
      let tmpItem = this.orderTable.find(item => item.orderNo == val) || {mobile: '', stuName: ''}
      console.log("选中的值",val,tmpItem.stuName, tmpItem)
      this.dropInfo.dropMobile = tmpItem.mobile
      this.dropInfo.dropName = tmpItem.stuName
    },
		// 获取付款银行列表
		async getPayBankList(){
			if(!(this.orderTable[0] && this.orderTable[0].pkOrg)) return
      // type 0 收款 1 退费
			const { data } = await this.$fetch("findFundMethod", { schoolId: this.orderTable[0].pkOrg, type: 1 })
			if(data){
				this.payBankList = data
			}
		},
		onBankAccountPkChange(val){
			const selectItem = this.payBankList.find(item => item.bankAccountPk == val) || {accountName: ''}
			this.dropoutForm.ncAccountName = selectItem.accountName
		},
    selectChange(value) {
      this.$emit('checkPayTypeChange', value);
    }
	}
}
</script>

<style scoped>
  .border {
    margin-top: 10px;
    border: 1px solid #E1E4EB;
    padding: 10px 18px 18px;
  }
</style>